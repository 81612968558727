<template>
<div id="page-sucursales">
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </div>
  <v-container fluid fill-height>
    <v-row align-center justify-center>
      <v-col>
        <base-material-card color="primary" icon="library_books" title="Facturas Globales" class="elevation-1 px-5 py-3">
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn fab dark x-small color="secondary" slot="activator" @click.native="openModal(1)" title="Generar Factura Global" v-tippy>
              <v-icon dark>add</v-icon>
            </v-btn>
          </v-card-actions>

          <v-card-text>
            <div id="datagrid" v-on:keyup.enter="registros.items = []; $refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');">

              <v-client-table ref="vuetable" :data="registros.items" :columns="columns" :options="options" class="elevation-2 pa-2">
                <template slot="noResults">
                  <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                </template>

                <template slot="fecha_timbrado" slot-scope="props">
                  {{props.row.fecha_timbrado.substr(0,10)}}
                </template>

                <template slot="fecha" slot-scope="props">
                  {{props.row.fecha.substr(0,10)}}
                </template>

                <template slot="total_ventas" slot-scope="props">
                  {{props.row.ids.length}}
                </template>

                <template slot="subtotal" slot-scope="props">
                  <div style="text-align:right;">${{formatNumberDec(props.row.subtotal,2)}}</div>
                </template>

                <template slot="impuestos" slot-scope="props">
                  <div style="text-align:right;">${{formatNumberDec(props.row.impuestos,2)}}</div>
                </template>

                <template slot="total" slot-scope="props">
                  <div style="text-align:right;">${{formatNumberDec(props.row.total,2)}}</div>
                </template>


                <template slot="error" slot-scope="props">
                  <span v-if="props.row.error != null && props.row.error != ''">{{props.row.error}}</span>
                  <span>{{props.row.error_cancelar_factura}}</span>
                </template>

                <template slot="actions" slot-scope="props">
                  <v-speed-dial v-model="props.row.id" direction="left" open-on-hover>
                    <template v-slot:activator>
                      <v-btn x-small fab dark color="primary">
                        <v-icon v-if="fab[props.row.id]">mdi-close</v-icon>
                        <v-icon v-else>reorder</v-icon>
                      </v-btn>
                    </template>
                    <v-btn  v-if="props.row.estatus == 'Pendiente' && props.row.id_facturacion != null || true" x-small fab dark color="pink" @click.native="verificar_estatus(props.row, props.row.ids, props.row.id_facturacion )" title="Verificar Estatus" v-tippy>
                      <v-icon>refresh</v-icon>
                    </v-btn>
                    <v-btn x-small v-if="props.row.estatus == 'Facturado'" fab dark color="success" @click.native="get_file(props.row.id_facturacion + '.xml')" title="Descargar XML" v-tippy>
                      <v-icon>code</v-icon>
                    </v-btn>
                    <v-btn x-small v-if="props.row.estatus == 'Facturado'" fab dark color="error" @click.native="get_file(props.row.id_facturacion + '.pdf')" title="Descargar PDF" v-tippy>
                      <v-icon>picture_as_pdf</v-icon>
                    </v-btn>
                    <v-btn x-small v-if="props.row.estatus == 'Facturado'" fab dark color="warning"
                      @click.native="modal_cancelar=true; id_solicitud_cancelado = props.row.id_facturacion; id_venta_cancelado = props.row._id; docs_for_update = props.row.ids" title="Cancelar Factura" v-tippy>
                      <v-icon>cancel</v-icon>
                    </v-btn>
                    <v-btn  v-if="props.row.estatus != 'Facturado'" x-small fab dark color="pink" @click.native="deleteRegistro(props.row._id, props.row._rev )" title="Eliminar Registro" v-tippy>
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </v-speed-dial>
                </template>
              </v-client-table>
              <PaginacionComponent ref="pagination_pw" :props="props_paginacion"></PaginacionComponent>
            </div>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

  <v-dialog v-model="modal" max-width="500px">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">add</v-icon>&nbsp;
          <span class="subheading">
            <strong>Nueva Factura Global</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modal = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md @keyup.enter="saveRegistro()">
            <v-row>
              <v-col sm="12">
                <v-menu offset-x ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" min-width="290px" offset-y>
                  <template v-slot:activator="{ on }">
                    <v-text-field v-on="on" v-model="model.fecha_de" label="Fecha" color="secondary" prepend-icon="event" @blur="model.fecha_de = parse_date(model.fecha_de)" :rules="[rules.required,valida_fecha]">
                    </v-text-field>
                  </template>

                  <v-date-picker v-model="model.fecha_de" color="secondary" scrollable locale="es-mx" @input="menu1 = false">
                    <v-spacer />

                    <v-btn color="secondary" x-small @click="menu1 = false">
                      Cancelar
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modal = false">
          <v-icon>cancel</v-icon> Cancelar
        </v-btn>
        <v-btn color="success" @click.native="saveRegistro()">
          <v-icon>done</v-icon> Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="modal_cancelar" max-width="700px">
    <v-card>
      <v-card-title>
        <span class="headline">Cancelar CFDI</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form ref="formempresa">
            <v-card width="100%">
              <v-card-text>
                <h5>¿Realmente desea cancelar el CFDI?</h5>
                <v-autocomplete v-model="motivo" label="Debe especificar el Motivo de Cancelación" :items="motivos" :hide-no-data="true" item-text="nombre" item-value="clave">
                </v-autocomplete>

                <v-text-field v-if="motivo=='01'" label="Folio Sustituto" v-model="folio_sustituto" :rules="[rules.required, validaFolio]" />
              </v-card-text>
            </v-card>

          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default" @click="modal_cancelar=false">
          <v-icon small>mdi-cancel</v-icon> NO
        </v-btn>
        <v-btn color="success" @click="cancelar_factura(id_solicitud_cancelado, id_venta_cancelado)" :disabled="motivo == ''">
          <v-icon small>mdi-check-circle-outline</v-icon> SI
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import PaginacionComponent from '@/components/PaginacionComponent.vue';

export default {
  components: {
    "PaginacionComponent": PaginacionComponent
  },
  watch: {
    model_filters: {
      handler(val) {
        // this.$nextTick(() => {
        //   this.registros.items = [];
        //   this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        // });
      },
      deep: true
    }
  },
  mounted: function() {
    this.model_filters = this.$refs.vuetable.$refs.table.query;
    if (!this.verificaPermiso('t.facturas.globales')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
  },
  created: async function() {
    window.dialogLoader.hide();
  },
  data() {
    return {
      menu1: false,
      docs_for_update: [],
      motivos: [{
          clave: "01",
          nombre: "Comprobantes emitidos con errores con relación"
        },
        {
          clave: "02",
          nombre: "Comprobantes emitidos con errores sin relación"
        },
        {
          clave: "03",
          nombre: "No se llevó a cabo la operación"
        },
        {
          clave: "04",
          nombre: "Operación nominativa relacionada en una factura global"
        }
      ],
      id_venta_cancelado: "",
      id_solicitud_cancelado: "",
      motivo: "",
      modal_cancelar: false,
      folio_sustituto: "",
      doc_factura: null,
      modal: false,
      encontrado: false,
      error_timbre: null,
      facturas_encontradas: [],
      json: {},
      api: 'https://ozu2hub36m.execute-api.us-east-1.amazonaws.com/dev/',
      model_filters: "",
      props_paginacion: {
        infiniteHandler: this.infiniteHandler,
        total_registros: 0
      },
      fab: [],
      name: "datagrid",
      fechaUsuario: "",
      columns: [
        "id_facturacion",
        "fecha",
        "total_ventas",
        "subtotal",
        "impuestos",
        "total",
        "estatus",
        "error",
        "fecha_timbrado",
        "fecha_cancelado",
        "actions"
      ],
      show_loading: true,
      options: {
        filterable: ["id_facturacion", "subtotal", "total_ventas", "impuestos", "total", "fecha_timbrado", "estatus", "error", "fecha", "fecha_cancelado"],
        sortable: ["id_facturacion", "subtotal", "total_ventas", "impuestos", "total", "fecha_timbrado", "estatus", "error", "fecha", "fecha_cancelado"],

        headings: {
          "fecha": "Día",
          "id_facturacion": "ID Power-CFDI",
          "subtotal": "Subtotal",
          "total_ventas": "# de Ventas",
          "impuestos": "Impuestos",
          "total": "Total",
          "fecha_timbrado": "Fecha Timbrado",
          "fecha_cancelado": "Fecha Cancelación",
          "actions": "Operaciones"
        },
      },
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Facturas",
          disabled: true,
          href: ""
        },
        {
          text: "Facturas Globales",
          href: ""
        }
      ],
      model: {
        _id: "",
        _rev: "",
        fecha: "",
        sucursal: "",
        ids: [],
      },
      rules: {
        required: v => !!v || "Este campo es requerido",
      },
      search: "",
      registros: {
        selected: [],
        items: []
      },
      meses: {}

    };
  },
  methods: {
    parse_date(date) {
      if (!date) return null;
      return moment(String(date)).format("YYYY-MM-DD");
    },
    valida_fecha: function(fecha) {
      if (fecha == "Invalid date")
        return "Fecha no válida."
      else
        return true;
    },
    validaFolio: function(val) {
      const pattern = /^[a-f0-9A-F]{8}-[a-f0-9A-F]{4}-[a-f0-9A-F]{4}-[a-f0-9A-F]{4}-[a-f0-9A-F]{12}$/;
      if (pattern.test(val)) {
        return true;
      } else {
        return "Folio mal formado";
      }
    },
    infiniteHandler: function($state) {
      var rows_per_page = 10;
      var page = Math.ceil(this.registros.items.length / rows_per_page); // == 1
      var skip = page * rows_per_page;
      let where = {
        "type": "facturas_globales",
        
        "fecha": {
          "$exists": true
        } //Solo para cumplir con el indice, sino no lo agarra
      };

      this.options.filterable.forEach(x => {
        if (this.model_filters[x]) {
          where[x] = {
            "$regex": "(?i)" + this.model_filters[x].toString().replace("(", "\\(").replace(")", "\\)")
          }
        }
      });


      axios({
          method: 'POST',
          url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
          withCredentials: true,
          data: {
            limit: rows_per_page,
            skip: skip,
            selector: where,
            "fields":["_id", 
                      "_rev",
                      "id_facturacion",
                      "fecha",
                      "total_ventas",
                      "subtotal",
                      "impuestos",
                      "total",
                      "estatus",
                      "error",
                      "fecha_timbrado",
                      "fecha_cancelado", "ids"],
            sort: [{
              "type": "desc"
            }, {
              "fecha": "desc"
            }],
            use_index: "_design/053036a30a6dce87e13c7aa0623e453e2258a3ee"
          },
        }).then(response => {
          if (response.data.docs.length) {
            var unique_records = new Set(this.registros.items.map(item => item._id));
            unique_records = Array.from(unique_records.values());
            var nuevos = response.data.docs.filter(e => !unique_records.includes(e._id));
            this.registros.items = this.registros.items.concat(nuevos);
            /*this.registros.items.sort(function (a, b) {
                //Ordenar por fecha más reciente
                return new Date(b.fecha) - new Date(a.fecha);
            });*/
            this.props_paginacion.total_registros = this.registros.items.length;
            $state.loaded();
          } else {
            $state.complete();
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    get_file: function(key) {

      var self = this;
      var data_xml = {
        "key": "BGU960207S5A/" + key
      };
      let url = this.api + 'carta_porte/get_download_url_mongo/';

      window.dialogLoader.show('Espere un momento por favor..');

      axios.post(url, data_xml)
        .then((res) => {
          window.dialogLoader.hide();
          if (typeof res.data !== 'undefined') {
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = res.data;
            a.target = "_blank";
            a.click();
            a.parentNode.removeChild(a);
          }
        })
        .catch((error) => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener los catalogos del SAT.",
            footer: ""
          });
          window.dialogLoader.hide();
          console.log("error al bajar el xml", error);
        });

    },
    formatNumberDec: function(numero, decimales){
        if (numero == undefined || numero == null || numero == "")
            return "";
        numero = Number(numero);
        if (isNaN(numero)){
            return "";
        } else
            return (numero).toFixed(decimales).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    },
    openModal: function(type) {
      this.model.fecha_de = "";
      this.model.sucursal = "";
      this.model.ids = [];
      this.doc_factura = null;

      this.error_timbre = null;
      this.encontrado = false;
      this.facturas_encontradas = [];
      this.docs_for_update = [];

      this.modal = true;
    },
    saveRegistro: function() {
      if (this.$refs.form.validate()) {
        window.dialogLoader.show('Espere un momento por favor..');
        this.modal = false;

        var fecha_de = this.model.fecha_de + "T00:00:00.000Z";
        var fecha_a = this.model.fecha_de + "T23:59:59.000Z";

        let data = {
          "selector": {
            "type": "ventas",
            "estatus": "Finalizada",
            "fecha": {
              "$gte": fecha_de,
              "$lte": fecha_a
            }
          },
          "limit": 1000000
        };
        var self = this;

        window.axios
          .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
          .then(response => {
            if (response.data.docs.length > 0) {
              self.facturas_encontradas = response.data.docs;
              self.encontrado = true;
            } else {
              this.encontrado = false;
              window.dialogLoader.hide();
              this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "No se encontró ninguna venta sin facturar en el día seleccionado",
                footer: ""
              });
            }
          })
          .catch(error => {
            this.$swal({
              type: "error",
              title: "¡Operación no Permitida!",
              text: "Ocurrió un error al obtener el registro",
              footer: ""
            });
          }).then(async function() {
            if (self.encontrado) {
              await self.preparar_json();
            }
            // window.dialogLoader.hide();
          });
      }
    },
    preparar_json: async function() {
      this.json = this.clean_model_json();

      var total = 0;
      var subtotal = 0;
      var descuento = 0;
      var importe = 0;
      var impuesto_doc = 0;

      this.model.ids = [];
      var docs_bulk = [];

      var conceptos = [];

      var impuestos = {
        "TotalImpuestosTrasladados": 0,
        "TotalImpuestosRetenidos": 0,
        "Traslados": []
      };
      var impuestos_raw = [];

      this.facturas_encontradas.forEach((item) => {
        var impuestos_venta = [];
        var concepto_venta = {
          "Cantidad": "1",
          "ClaveProdServ": "01010101",
          "ClaveUnidad": "ACT",
          "Descripcion": "Venta",
          "Importe": item.subtotal.toFixed(2).toString(),
          "ValorUnitario": item.subtotal.toFixed(2).toString(),
          "Impuestos": {
            "Traslados": []
          },
          "NoIdentificacion": item.ticket
        };

        item.estatus = "Factura Global"
        docs_bulk.push(item);

        this.model.ids.push(item._id);

        // total += parseFloat(item.total.toFixed(2));
        subtotal += parseFloat(item.subtotal.toFixed(2));
        descuento += 0;

        for (let con in item.articulos) {
          importe += item.articulos[con].subtotal;
          item.articulos[con].impuestos.forEach((im) => {
            let impuesto = {
              "TipoFactor": "Tasa",
              "Importe": parseFloat(item.articulos[con].subtotal.toFixed(6)) * (parseFloat(im.tasa) / 100),
              "Impuesto": im.clave,
              "TasaOCuota": (im.tasa / 100).toString().padEnd(8, "0"),
              "Base": parseFloat(item.articulos[con].subtotal.toFixed(6))
            };
            impuestos_raw.push(impuesto);
            impuestos_venta.push(impuesto);
          });
        }

        impuestos_venta.forEach((imp) => {
          var existe = concepto_venta.Impuestos.Traslados.filter(a => a.TasaOCuota == imp.TasaOCuota && a.Impuesto == imp.Impuesto);
          if (existe.length > 0) {
            existe = existe[0];
            existe.Importe += parseFloat(imp.Importe.toFixed(6));
            existe.Base += parseFloat(imp.Base.toFixed(6));
          } else {
            concepto_venta.Impuestos.Traslados.push({
              "TipoFactor": "Tasa",
              "Importe": parseFloat(imp.Importe.toFixed(6)),
              "Impuesto": imp.Impuesto.toString(),
              "TasaOCuota": imp.TasaOCuota,
              "Base": parseFloat(imp.Base.toFixed(6))
            });
          }
        });

        concepto_venta.Impuestos.Traslados.forEach((imp) => {
          imp.Base = imp.Base.toFixed(6).toString();
          let importe = parseFloat(imp.Base) * parseFloat(imp.TasaOCuota);
          imp.Importe = importe.toFixed(6).toString();
        });

        conceptos.push(concepto_venta)
      });

      impuestos_raw.forEach((imp) => {
        var existe = impuestos.Traslados.filter(a => a.TasaOCuota == imp.TasaOCuota && a.Impuesto == imp.Impuesto);
        if (existe.length > 0) {
          existe = existe[0];
          existe.Importe += parseFloat(imp.Importe);
          existe.Base += parseFloat(imp.Base);
        } else {
          impuestos.Traslados.push({
            "TipoFactor": "Tasa",
            "Importe": parseFloat(imp.Importe.toFixed(6)),
            "Impuesto": imp.Impuesto.toString(),
            "TasaOCuota": imp.TasaOCuota,
            "Base": parseFloat(imp.Base.toFixed(6))
          });
        }
        // impuestos.TotalImpuestosTrasladados += parseFloat(imp.Importe.toFixed(6));
      });
      impuestos_raw.forEach((imp) => {
          imp.Base = imp.Base.toFixed(6).toString();
          let importe = parseFloat(imp.Base) * parseFloat(imp.TasaOCuota);
          imp.Importe = importe.toFixed(6).toString();
      });

      impuestos.Traslados.forEach((tra) => {
        // let bb = parseFloat(tra.TasaOCuota) > 0 ? parseFloat(tra.Importe) / parseFloat(tra.TasaOCuota): tra.Base;

        //tra.Importe = tra.Importe.toFixed(2).toString();

        tra.Importe = parseFloat(tra.Base) * parseFloat(tra.TasaOCuota);
        tra.Importe = tra.Importe.toFixed(2).toString();
        tra.Base = tra.Base.toFixed(2).toString();

        impuestos.TotalImpuestosTrasladados += parseFloat(tra.Importe);
      });

      impuestos.TotalImpuestosRetenidos = impuestos.TotalImpuestosRetenidos.toFixed(2).toString();
      impuestos.TotalImpuestosTrasladados = impuestos.TotalImpuestosTrasladados.toFixed(2).toString();

      for (var i = 0; i < conceptos.length; i++) {
        if (conceptos[i].Impuestos.Traslados.length > 0) {
          conceptos[i]['ObjetoImp'] = "02";
        } else {
          conceptos[i]['ObjetoImp'] = "01";
          conceptos[i]['Impuestos'] = null;
        }
      }

      impuesto_doc = impuestos.TotalImpuestosTrasladados;

      total = parseFloat(subtotal) + parseFloat(impuesto_doc);

      
      this.json = {
        "Sello": "",
        "Serie": "G" + this.model.fecha_de.substring(2, 4) + this.model.fecha_de.substring(5, 7),
        "Folio": this.model.fecha_de.substring(8, 10),
        "SubTotal": subtotal.toFixed(2).toString(),
        "TipoDeComprobante": "I",
        "Total": total.toFixed(2).toString(),
        "Version": "4.0",
        "Fecha": this.model.fecha_de + "T23:59:00",
        "LugarExpedicion": "98600",
        "Moneda": "MXN",
        "NoCertificado": "",
        "MetodoPago": "PUE",
        "FormaPago": "01",
        "Exportacion": "01",
        "InformacionGlobal": {
          "Periodicidad": "01",
          "Meses": this.model.fecha_de.substring(5, 7),
          "Año": this.model.fecha_de.substring(0, 4)
        },
        "Emisor": {
          "Nombre": "LA BODEGUITA DE GUADALUPE",
          "RegimenFiscal": "601",
          "Rfc": "BGU960207S5A"
        },
        "Receptor": {
          "Nombre": "PUBLICO EN GENERAL",
          "Rfc": "XAXX010101000",
          "UsoCFDI": "S01",
          "RegimenFiscalReceptor": "616",
          "DomicilioFiscalReceptor": "98600"
        },
        "Conceptos": conceptos,
        "Impuestos": impuestos,
      };
     
      let data = {
        type: "facturas_globales",
        fecha_creado: window.moment().format("YYYY-MM-DDTHH:mm:ss"),
        total: total.toFixed(2).toString(),
        subtotal: subtotal.toFixed(2).toString(),
        descuento: descuento.toFixed(2).toString(),
        impuestos: impuesto_doc,
        estatus: "Pendiente",
        fecha: this.model.fecha_de,
        ids: this.model.ids,
        json: this.json,
        fecha_timbrado: "",
        id_facturacion: null,
        error: null
      };
      this.doc_factura = data;

      await window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', data)
        .then(async response => {
          console.log("guardado", response);
          this.doc_factura._id = await response.data.id;
          this.doc_factura._rev = await response.data.rev;
          // this.registros.items = [];
          // this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        })
        .catch(error => {
          window.dialogLoader.hide();
          this.registros.items = [];
          this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
          console.log("error al guardar global", error);
          return;
        });

      this.docs_for_update = docs_bulk;

      await this.facturar();

    },
    clean_model_json: function() {
      return {
        "Sello": "",
        "Serie": "",
        "Folio": "",
        "SubTotal": "",
        "TipoDeComprobante": "I",
        "Total": "",
        "Version": "4.0",
        "Fecha": "",
        "LugarExpedicion": "",
        "Moneda": "",
        "NoCertificado": "",
        "MetodoPago": "",
        "FormaPago": "",
        "Exportacion": "",
        "Emisor": {
          "Nombre": "",
          "RegimenFiscal": "",
          "Rfc": ""
        },
        "Receptor": {
          "Nombre": "",
          "Rfc": "",
          "UsoCFDI": "",
          "RegimenFiscalReceptor": "",
          "DomicilioFiscalReceptor": ""
        },
        "Conceptos": null,
        "Impuestos": null,
      }
    },
    facturar: async function() {
      var key_xml = null;

      var datos_api = {
        "rfc": this.json.Emisor.Rfc,
        "json": this.json
      }

      var id_solicitud = null;

      await axios.post(this.api + 'carta_porte/guardar_mongo/', datos_api)
        .then(async (res) => {
          if (typeof res.data !== 'undefined') {
            id_solicitud = res.data.id_solicitud;
            var contador = 0;
            var flag = 0;
            var self = this;
            do {
              await new Promise(r => setTimeout(r, 15000));
              await self.consultar_estatus(id_solicitud).then(r => {
                flag = r;
              }).catch(er => {
                flag = er;
              });
              console.log(flag);
              contador += 1;
            } while (flag != 1 && flag != 3 && contador < 6);

            if (flag == 1) {
              // Se cambian los estatus de las facturas
              let url_bulk = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_bulk_docs";
              await window.axios.post(url_bulk, {
                  "docs": this.docs_for_update
                })
                .then(async response => {
                  console.log("bulk hecho");
                })
                .catch(error => {
                  console.log("error en el bulk:", error);
                });
              await self.send_mail(id_solicitud);
            } else {
              console.log(this.error_timbre);
              this.doc_factura.id_facturacion = id_solicitud;
              this.doc_factura.error = this.error_timbre;
              this.doc_factura.estatus = "Error";

              window.axios
                .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.doc_factura._id + '?conflicts=true', this.doc_factura)
                .then(response => {
                  window.dialogLoader.hide();
                  this.modal = false;
                  this.registros.items = [];
                  this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                })
                .catch(error => {
                  console.log("error guarar error", error);
                  this.registros.items = [];
                  this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                  window.dialogLoader.hide();
                  this.modal = false;
                  return;
                });

            }
            // window.dialogLoader.hide();
          }
        })
        .catch((error) => {
          this.$swal({
            type: "error",
            title: "Error",
            text: "Ocurrió un error al guardar el registro.",
            footer: "",
          });
          window.dialogLoader.hide();
        });
    },
    consultar_estatus: function(id_solicitud) {
      var self = this;
      return new Promise(async function(resolve, reject) {
        await axios.post(self.api + 'carta_porte/solicitud_mongo/', {
            "id_solicitud": id_solicitud
          })
          .then((res) => {
            if (typeof res.data !== 'undefined') {
              if (res.data.estatus == 1) {
                return resolve(1);
              } else if (res.data.estatus > 1) {
                self.error_timbre = res.data.error_solicitud;
                return resolve(3);
              } else {
                return resolve(2);
              }
            } else {
              console.log("en el undefined");
              return resolve(2);
            }
          })
          .catch((error) => {
            console.log("en el catch", error);
            return reject(3);
          });

      });
    },
    send_mail: function(id_solicitud) {
      console.log("enviar los correos");
      var key_xml = id_solicitud;
      this.doc_factura.id_facturacion = id_solicitud;
      this.doc_factura.estatus = "Facturado";
      this.doc_factura.fecha_timbrado = window.moment().format("YYYY-MM-DDTHH:mm:ss");

      window.axios
        .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.doc_factura._id + '?conflicts=true', this.doc_factura)
        .then(response => {
          window.dialogLoader.showSnackbar('El registro se actualizó correctamente..', {
            color: 'success'
          });
          this.registros.items = [];
          this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        })
        .catch(error => {
          window.dialogLoader.showSnackbar('Ocurrió un error al actualizar el registro..', {
            color: 'error'
          });
        }).then(() => {
          window.dialogLoader.hide();
          this.registros.items = [];
          this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        });
    },
    cancelar_factura: async function(id_facturacion, id_venta) {
      this.$swal({
        type: "info",
        title: "Cuidado",
        text: "¿Está seguro de cancelar la factura?",
        footer: "",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar"
      }).then(async result => {
        var url = this.api + "carta_porte/cancelar_mongo/";
        var url_test = "http://localhost:8000/carta_porte/cancelar_mongo/";
        var params = {
          "id_solicitud": id_facturacion,
          "motivo": this.motivo
        };

        let query = {
          "selector": {
            "type": "ventas",
            "estatus": "Factura Global",
            "_id": {
              "$in": this.docs_for_update
            }
          },
          "limit": 1000000
        };
        var self = this;

        await window.axios.post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', query).then(async response => {
            if (response.data.docs.length > 0) {
              self.docs_for_update = await response.data.docs;
            } else {
              self.docs_for_update = await [];
            }
          })
          .catch(error => {
            console.log("error buscando ventas para actualizar estatus");
          });

        await self.docs_for_update.forEach((doc) => {
          doc.estatus = "Finalizada";
        });

        if (this.motivo == '01') {
          params.folio_sustituto = this.folio_sustituto;
        }
        var url_update = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_design/funciones_configuracion/_update/factura_cancelar/" + id_venta;

        if (result.value) {
          window.axios.post(url, params).then(response_power => {
            if (response_power.data == true) {

              //Aqui hay que cambiar a estatus de cancelada
              window.dialogLoader.show('Espere un momento por favor..');
              window.axios
                .put(url_update, {
                  "estatus": "Factura Cancelada",
                  "fecha_cancelado": window.moment().format("YYYY-MM-DDTHH:mm:ss")
                })
                .then(async response => {
                  if (response.data == true) {
                    // Se cambian las ventas a Finalizada ya que la global se cancela
                    let url_bulk = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_bulk_docs";
                    await window.axios.post(url_bulk, {
                        "docs": this.docs_for_update
                      })
                      .then(async response => {
                        console.log("bulk hecho");
                      })
                      .catch(error => {
                        console.log("error en el bulk:", error);
                      });

                    window.dialogLoader.showSnackbar('El registro se actualizó correctamente..', {
                      color: 'success'
                    });
                  } else {
                    console.log("no se pudo actualizar la factura global")
                  }
                })
                .catch(error => {
                  console.log("error", error);
                }).then(() => {
                  window.dialogLoader.hide();
                  this.modal_cancelar = false;
                  this.folio_sustituto = null;
                  this.motivo = null;
                  this.id_solicitud_cancelado = null;
                  this.id_venta_cancelado = null;
                });
            } else {
              console.log("error al actualizar en power cfdi");
            }
          }).catch(async error2 => {
            await this.error_cancelar(id_venta, error2.response.data.mensaje);

            this.modal_cancelar = false;
            this.folio_sustituto = null;
            this.motivo = null;
            this.id_solicitud_cancelado = null;
            this.id_venta_cancelado = null;
            window.dialogLoader.showSnackbar('Ocurrio un error al cancelar...', {
              color: 'error'
            });

          });

        }

      });
    },
    error_cancelar: async function(id_venta, mensaje) {
      console.log("error: ", mensaje);
      var url_error = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_design/funciones_configuracion/_update/factura_error/" + id_venta;
      await window.axios.put(url_error, {
          "estatus": "Error al Cancelar",
          "error_cancelar_factura": mensaje
        })
        .then(async response => {
          if (response.data == true) {

          } else {
            window.dialogLoader.showSnackbar('Cancelar: Error al actualizar el estatus de la factura', {
              color: 'error'
            });
          }
        })
        .catch(error => {
          console.log("error-cancelar", error);
        });

        this.registros.items = [];
        this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
    },
    deleteRegistro: function(_id, _rev) {
      this.$swal({
        type: "info",
        title: "Cuidado",
        text: "¿Está seguro de eliminar el registro?",
        footer: "",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          window.dialogLoader.show('Espere un momento por favor..');
          window.axios
            .delete(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + _id + "?rev=" + _rev)
            .then(response => {
              window.dialogLoader.showSnackbar('El registro se eliminó correctamente..', {
                color: 'success'
              });
              this.registros.items = [];
              this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
            })
            .catch(error => {
              window.dialogLoader.showSnackbar('Ocurrió un error al eliminar el registro..', {
                color: 'error'
              });
            }).then(() => {
              window.dialogLoader.hide();
            });

        }

      });
    },
    verificar_estatus:async function(factura_global, ventas, id_power_devel) {
      window.dialogLoader.show('Espere un momento por favor..');
      var flag = 0 
      var contador = 0

      do {
        await new Promise(r => setTimeout(r, 15000));
        await this.consultar_estatus(id_power_devel).then(r => {
          flag = r;
        }).catch(er => {
          flag = er;
        });
        console.log(flag);
        contador += 1;
      } while (flag != 1 && flag != 3 && contador < 6);

      if(flag == 1) {
        const query = {
          "selector": {
            "_id": {"$in": ventas}
          },
          "limit": 1000000
        };

        var ventas_detalles = [];

        await window.axios.post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', query)
        .then(response => {
          if (response.data.docs.length > 0) {
            ventas_detalles = response.data.docs;
          } 
        }).catch(error => {
          ventas_detalles = [];
        }).then(() => {
          console.log("aqui ya termino la query")
        });

        if(ventas_detalles.length == 0) {
          window.dialogLoader.hide();
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener el registro",
            footer: ""
          });
          return
        }

        ventas_detalles.forEach(x => {
          if (x.estatus != 'Facturada') {
            x.estatus = "Factura Global"
          }
        })

        //Se hace el bulk para actualizar las ventas
        let url_bulk = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_bulk_docs";
        await window.axios.post(url_bulk, {
          "docs": ventas_detalles
        })
        .then(async response => {
          console.log("bulk hecho");
        })
        .catch(error => {
          console.log("error en el bulk:", error);
        });

        // Se pone la factura global como facturada
        /* factura_global.id_facturacion = id_power_devel;
        factura_global.estatus = "Facturado";
        factura_global.fecha_timbrado = window.moment().format("YYYY-MM-DDTHH:mm:ss");

        window.axios
          .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + factura_global._id + '?conflicts=true', factura_global)
          .then(response => {
            window.dialogLoader.showSnackbar('El registro se actualizó correctamente..', {
              color: 'success'
            });
            this.registros.items = [];
            this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
          })
          .catch(error => {
            window.dialogLoader.showSnackbar('Ocurrió un error al actualizar el registro..', {
              color: 'error'
            });
          }).then(() => {
            window.dialogLoader.hide();
            this.registros.items = [];
            this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
          });
 */
        window.dialogLoader.hide();
      } else if(flag == 0){
        window.dialogLoader.hide();
        console.log("creo que no hubo respuesta de power")
        this.$swal({
            type: "info",
            title: "¡Operación no Permitida!",
            text: "No se pudo establecer comunicación con Power-CFDI",
            footer: ""
          });

        return
      } else {
        factura_global.error = this.error_timbre;
        factura_global.estatus = "Error";

        window.axios
          .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + factura_global._id + '?conflicts=true', factura_global)
          .then(response => {
            window.dialogLoader.hide();
            this.modal = false;
            this.registros.items = [];
            this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
            return
          })
          .catch(error => {
            console.log("error guarar error", error);
            this.registros.items = [];
            this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
            window.dialogLoader.hide();
            this.modal = false;
            return;
          });
      }
    }
  }
};
</script>
